import {ApplicationState, reducers} from './'
import {combineReducers, configureStore as reactConfigureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { History } from 'history';

export default function configureStore(history: History, initialState?: ApplicationState) {

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return reactConfigureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        enhancers,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history))
    });

}