import React, {PureComponent} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../store';
import { MainState } from './Reducer';
import { action } from './Action';
import { Menu, PageHeader, Button, Image, Row, Col, Typography, Card } from 'antd';
import logo from "../../media/logo.png";
import cgo from '../../media/cgo.png';
import huttsee from '../../media/huttsee.png';
import {AiOutlineMail} from 'react-icons/ai';
import {CSSTransition} from "react-transition-group";
import { InitialEntry } from 'history';
import { TypePredicateKind } from 'typescript';

const {Text} = Typography;

const mapState = (state: ApplicationState) => ({
    ...state.main
})
  
const mapDispatch = {
    action
}
  
// const connector = connect(mapState, mapDispatch)

const actionTypes = {
    action
};

const initialState = {
    show: false
}

const endListener = (node: HTMLElement, done: () => void) => {
    node.addEventListener("transitionend", done, false);
}

const styles = {
    mainContent: {
        marginTop: "5%"
    },
    mainText: {
        fontSize: 45,
        color: "white",
        fontWeight: 300
    },
    mainSubtext: {
        color: "white",
        fontSize: 17
    },
    secondaryContent: {
        backgroundColor: "white",
    },
    secondaryText: {
        fontSize: 35
    },
    cardShadow: {
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },
    footer: {
        backgroundColor: "#000c17",
    },
    companyContent: {
        backgroundColor: "black"
    },
    companyText: {
        fontSize: 35
    }
};

type MainProps = MainState & typeof actionTypes;

class MainComponent extends PureComponent<MainProps> {

    state=initialState

    componentDidMount() {
        this.props.action();
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader
                    backIcon={<Image width={40} src={logo} preview={false} style={{backgroundColor: "#d1d1d1", borderRadius: 50}} />}
                    onBack={()=>{}}
                    ghost={false}
                    title="Midnite"
                    subTitle="Systems"
                    // extra={[
                    //     <Button key="3">Operation</Button>,
                    //     <Button key="2">Operation</Button>,
                    //     <Button key="1" type="primary">
                    //     Primary
                    //     </Button>,
                    // ]}
                    >
                    </PageHeader>

                <div style={styles.mainContent}>
                    <Row justify="space-between">
                        <Col sm={8} md={0}>
                            <div className={'triangle-down'}></div>
                        </Col>
                        <Col sm={8} md={0}>
                            <div className={'triangle-down'}></div>
                        </Col>
                        <Col sm={8} md={0}>
                            <div className={'triangle-down'}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className={'triangle-right'}></div>
                            <div className={'triangle-right'}></div>
                            <div className={'triangle-right'}></div>
                        </Col>
                        <Col md={8} style={{marginTop: "50px", marginBottom: "50px"}}>
                            <Text style={styles.mainText}>Have it done by Midnite</Text>
                            <br />
                            <Text style={styles.mainSubtext}>We specialize in systems software and mobile app development.</Text>
                        </Col>
                        <Col md={8} style={{right: 0, position: "absolute"}}>
                            <div className={'triangle-left'}></div>
                            <div className={'triangle-left'}></div>
                            <div className={'triangle-left'}></div>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col sm={8} md={0}>
                            <div className={'triangle-up'}></div>
                        </Col>
                        <Col sm={8} md={0}>
                            <div className={'triangle-up'}></div>
                        </Col>
                        <Col sm={8} md={0}>
                            <div className={'triangle-up'}></div>
                        </Col>
                    </Row>
                </div>
                <div style={styles.companyContent}>
                    <div style={{padding: 20}}>
                        <Row justify="center">
                            <Col sm={24} md={24}>
                                <Text style={Object.assign({}, styles.companyText, {color: "white"})}>Our</Text>&nbsp;&nbsp;<Text style={Object.assign({}, styles.companyText, {fontWeight: 300, color: "white"})}>Company</Text>
                            </Col>
                        </Row>
                        <br />
                        <Row justify="center">
                            <Col sm={24} md={24}>
                                <h2 style={{color: "white"}}>Mission</h2>
                                <Text style={{textAlign: "justify", textJustify: "inter-word", color: "white"}}>Our mission is to create systems software designed to grow with our clients needs, that stand the test of time in terms of performance, capability and compatibility.</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col sm={24} md={24}>
                                <h2 style={{color: "white"}}>Vision</h2>
                                <Text style={{textAlign: "justify", textJustify: "inter-word", color: "white"}}>Build a worldwide network of strong and reliable open systems software.</Text>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style={styles.secondaryContent}>
                    <div style={{padding: 20}}>
                        <Row justify="center">
                            <Col sm={24} md={24}>
                                <Text style={styles.secondaryText}>Our</Text>&nbsp;&nbsp;<Text style={Object.assign({}, styles.secondaryText, {fontWeight: 300})}>Products</Text>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col sm={8} md={8}>
                                <Card style={styles.cardShadow}>
                                    <Image src={cgo} style={{width: 100, borderRadius: 50, boxShadow: "0px 1px 5px grey"}}/>
                                    <h3>Couchbase Lite CGo</h3>
                                    <Text style={{textAlign: "justify", textJustify: "inter-word"}}>Go lang bindings for the couchbase-lite-C library.
                                        Click <a href="https://github.com/svr4/couchbase-lite-cgo">here</a> to view the repo.
                                    </Text>
                                </Card>
                            </Col>
                            <Col sm={8} md={8}>
                                <Card style={styles.cardShadow}>
                                    <Image src={huttsee} style={{width: 100, borderRadius: 50, boxShadow: "0px 1px 5px grey"}}/>
                                    <h3>Huttsee</h3>
                                    <Text style={{textAlign: "justify", textJustify: "inter-word"}}>
                                        The property happy place. Pay your rent easily and safetly.
                                        <br />
                                        <br />
                                    </Text>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style={styles.footer}>
                    <div style={{padding: 20}}>
                        <Row justify="start">
                            <Col span={8}>
                                <h3 style={{color: "white"}}>Contact Us</h3>
                                <Text style={{color: "white"}}><AiOutlineMail style={{fontSize: 16, color: "#ef3e2d"}} /> <a href="mailto:info@midnite.systems" >info@midnite.systems</a></Text>
                            </Col>
                        </Row>
                    </div>
                </div>
                
                {/* <CSSTransition
                    in={this.state.show}
                    timeout={300}
                    classNames="main"
                    unmountOnExit
                    addEndListener={endListener}
                >
                    <div>Test</div>
                </CSSTransition> */}
                
                {/* <br/>
                <br />
                <br/> */}
                {/* <button type="button" onClick={() => this.setState({show: true}, () => console.log("udpated"))}>
                    Click to Enter
                </button> */}
            </React.Fragment>
        )
    }
}

export default connect(mapState, mapDispatch)(MainComponent as any);