import { KnownAction } from "../../lib/types";

export interface MainState {

}

const initialState: MainState = {

};

export function MainReducer(state: MainState = initialState, action: KnownAction): MainState {
    switch(action.type) {
        default:
            return {
                ...state
            }
    }
}