// configure the store
// add reducers to the root reducer
// create types, representing functions, for using Thunks in redux.
// Redux toolkit already contains thunk middleware and will know how to use these functions.
import { Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk'
import {MainState, MainReducer} from '../components/main/Reducer';
import { KnownAction } from '../lib/types';

export interface ApplicationState {
    main: MainState | undefined
}

export const reducers = {
    main: MainReducer
}

export type AppThunkAction = ThunkAction<void, ApplicationState, unknown, Action<KnownAction>>;